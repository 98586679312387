import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import SentAttempts from './SentAttempts'
import { getMyTeam } from '../../api/services/team'
import { getMyStealAttempts } from '../../api/services/steal'
import ReceivedAttempts from './ReceivedAttempts'
import { NoItemContainer } from '../../components/common/NoItemContainer'
import { messages } from '../../messages'
import Navbar from '../../components/Navbar'
import { useSelector } from 'react-redux'
import { getLeagueInfo } from '../../api/services/leagues'
import { Box, Container } from '@mui/material'

export const Transactions = () => {
  const { manager } = useSelector((state) => state)
  const { type } = useSelector((state) => state.steal)

  const [managerData, setManagerData] = useState()

  // Get my team
  const {
    refetch: teamsRefetch,
    isError: teamsError,
    isLoading: teamsIsLoading,
  } = useQuery(['my-team-page', manager.id], () => getMyTeam(manager.id), {
    enabled: manager.id > 0,
    retry: false,
  })

  // Get received and sent transactions
  const {
    data: transactionsData,
    isLoading: transactionsIsLoading,
    refetch: transactionsRefetch,
  } = useQuery(
    ['my_steals', manager.id],
    () => getMyStealAttempts(manager.id),
    {
      enabled: manager.id > 0,
      retry: false,
    }
  )

  // Get updated league info
  const {
    data: leagueUpdatedData,
    isLoading: leagueUpdatedDataLoading,
    isFetching: leagueUpdatedDataFetching,
    refetch: leagueRefetch,
  } = useQuery(
    ['my_league_info', manager.league_id],
    () => getLeagueInfo(manager.league_id),
    {
      enabled: manager.league_id > 0,
      retry: false,
      onSuccess: (data) => currentManagerCashRemaining(data),
    }
  )

  if (teamsError) {
    return <NoItemContainer text={messages[6].noTeamSelected} />
  }

  // Refetch teams and transactions
  const myTeamPageRefetch = () => {
    teamsRefetch()
    transactionsRefetch()
    leagueRefetch()
  }

  if (!leagueUpdatedData?.is_locked && !leagueUpdatedDataLoading) {
    return <NoItemContainer text={messages[6].notLocked} />
  }

  const currentManagerCashRemaining = (data) => {
    const myManager = data.managers.filter(
      (singleManager) => singleManager.id === manager.id
    )[0]
    setManagerData(myManager)
  }

  return (
    <>
      <Navbar
        managerData={managerData}
        leagueUpdatedDataFetching={leagueUpdatedDataFetching}
      />
      <Container maxWidth="md" sx={{ padding: 0 }}>
        <Box position="relative" height={!type && 'calc(100vh - 150px)'} overflow="auto" padding={1} margin={0}>
          <ReceivedAttempts
            transactions={transactionsData}
            transactionsIsLoading={transactionsIsLoading}
            manager={managerData}
            myTeamPageRefetch={myTeamPageRefetch}
          />
          <SentAttempts
            transactions={transactionsData}
            teamsIsLoading={teamsIsLoading}
            refetch={transactionsRefetch}
            manager={managerData}
          />
        </Box>
      </Container>
    </>
  )
}
