import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Grid, Paper } from '@mui/material'
import { MatchArea } from './MatchArea'
import { messages } from '../../../messages'

export default function MatchAccordion({ matchups, sortByKind }) {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box borderTop="1px solid grey">
      <Accordion
        sx={{ border: 'none', boxShadow: 'none', padding: 0 }}
        id='otherLeagueMatchupsContainer'
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          sx={{ padding: 0, margin: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant='h6'>{messages[5].OtherLeagueMatchups}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ border: 'none', boxShadow: 'none', padding: 0 }} >
          <Grid container spacing={1}>
            {matchups.length > 1 ? (
              matchups.map(
                (matchup, id) =>
                  id > 0 && (
                    <Grid item xs={6} key={id}>
                      <Paper elevation={1}>
                        <MatchArea sortByKind={sortByKind} matchup={matchup} />
                      </Paper>
                    </Grid>
                  )
                )
              ) : (
                <Typography variant='h7'>{messages[6].noMoreMatchups}</Typography>
              )
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
