import { Container } from '@mui/system'
import React, { useState } from 'react'
import MyTeamsRoster from './MyTeamsRoster'
import { useQuery } from '@tanstack/react-query'
import { getMyTeam } from '../../api/services/team'
import { NoItemContainer } from '../../components/common/NoItemContainer'
import { messages } from '../../messages'
import Navbar from '../../components/Navbar'
import { useSelector } from 'react-redux'
import { getLeagueInfo } from '../../api/services/leagues'
import { Box } from '@mui/material'
import { RosterMoves } from './RosterMoves'

export const MyTeam = () => {
  const { manager } = useSelector((state) => state)
  const { type } = useSelector((state) => state.steal)

  const [managerData, setManagerData] = useState()

  // Get my team
  const {
    data: teamsData,
    isError: teamsError,
    isLoading: teamsIsLoading,
    refetch: teamsRefetch
  } = useQuery(['my-team-page', manager.id], () => getMyTeam(manager.id), {
    enabled: manager.league_id > 0,
    retry: false,
  })


  // Get updated league info
  const {
    data: leagueUpdatedData,
    isLoading: leagueUpdatedDataLoading,
    isFetching: leagueUpdatedDataFetching,
    refetch: leagueRefetch
  } = useQuery(
    ['my_league_info', manager.league_id],
    () => getLeagueInfo(manager.league_id),
    {
      enabled: manager.league_id > 0,
      retry: false,
      onSuccess: (data) => currentManagerCashRemaining(data),
    }
  )

  if (teamsError) {
    return <NoItemContainer text={messages[6].noTeamSelected} />
  }

  if (!leagueUpdatedData?.is_locked && !leagueUpdatedDataLoading) {
    return <NoItemContainer text={messages[6].notLocked} />
  }

  const currentManagerCashRemaining = (data) => {
    const myManager = data.managers.filter(
      (singleManager) => singleManager.id === manager.id
    )[0]
    setManagerData(myManager)
  }

  const myTeamPageRefetch = () => {
    teamsRefetch()
    leagueRefetch()
  }

  return (
    <>
      <Navbar
        showCashLeft
        managerData={managerData}
        leagueUpdatedDataFetching={leagueUpdatedDataFetching}
      />
      <Container maxWidth="md">
        <Box position="relative" height={!type && 'calc(100vh - 220px)'} overflow="auto">

          <MyTeamsRoster
            teamsIsLoading={teamsIsLoading}
            teams={teamsData}
            manager={manager}
          />

          <RosterMoves
            myTeamPageRefetch={myTeamPageRefetch}
            leagueUpdatedData={leagueUpdatedData}
          />
        </Box>
      </Container>
    </>
  )
}
