import React, { useState } from 'react'
import DashboardIcon from '@mui/icons-material/DashboardCustomizeOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Typography } from '@mui/material'
import SummaryTab from './SummaryTab'
import TeamsTab from './TeamsTab'

function DraftTabs() {
  const [selectedTab, setSelectedTab] = useState('draft')

  return (
    <Box sx={{ width: '100%', marginTop: '15px' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: selectedTab === 'draft' ? 'black' : 'gray',
            textDecoration: selectedTab === 'draft' ? 'none' : 'underline',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
          }}
          id="draftSummaryTab"
          onClick={() => setSelectedTab('draft')}
        >
          <DashboardIcon sx={{ marginRight: 1 }} />
          <Typography sx={{ fontWeight: 'bold' }} variant="body2">Draft Summary</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: selectedTab === 'teams' ? 'black' : 'gray',
            textDecoration: selectedTab === 'teams' ? 'none' : 'underline',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
          }}
          id="draftTeamsTab"
          onClick={() => setSelectedTab('teams')}
        >
          <MenuIcon sx={{ marginRight: 1 }} />
          <Typography sx={{ fontWeight: 'bold' }} variant="body2">Available Teams</Typography>
        </Box>
      </Box>
      {selectedTab === 'draft' && <SummaryTab />}
      {selectedTab === 'teams' && <TeamsTab setSelectedTab={setSelectedTab}/>}
    </Box>
  )
}

export default DraftTabs
