import React, { useEffect, useRef, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { resetDraftAuction } from '../../../../../store/slices/draftSlice'
import { StickyBox } from '../../../styles'
import { toast } from 'react-toastify'
import { timestampForLogs } from '../../../../../helpers/utils'

export const DraftHead = ({ draftOnlyRefetch, draftManagersRefetch, draftavailableTeamsRefetch }) => {
  const [timer, setTimer] = useState(null)

  const { auction } = useSelector((state) => state.draft)
  const { manager, draft } = useSelector((state) => state)
  const [maxRetriesReached, setMaxRetriesReached] = useState(0)

  const [clockSkew, setClockSkew] = useState(0)
  const [isUserOffline, setIsUserOffline] = useState(false)

  const manager_id = manager.id
  const draft_id = draft.draftStatus.id

  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // ---------------------  Check if user is active or not  --------------------
  let userTimer

  // When user is offline for more than 300 seconds, set inactive and show dialog
  const resetTimer = () => {
    clearTimeout(userTimer)
    userTimer = setTimeout(() => {
      onInactiveUser()
    }, 300000)
  }

  const handleUserActivity = () => {
    onActiveUser()
    resetTimer()
  }

  const onInactiveUser = () => {
    setIsUserOffline(true)
    //console.info(`draftSocketSend set_inactive: ${timestampForLogs()}`)

    draftSocketSend({
      action: 'set_inactive',
      manager_id,
    })
  }

  const onActiveUser = () => {
    if (isUserOffline) {
      setIsUserOffline(false)
      //console.info(`draftSocketSend set_active: ${timestampForLogs()}`)

      draftSocketSend({
        action: 'set_active',
        manager_id,
      })
    }
  }

  useEffect(() => {
    // Only inactive offline users when draft is in progress.
    if (draft.draftStatus.status === 'in_progress') {
      window.addEventListener('mousemove', handleUserActivity)
      window.addEventListener('keypress', handleUserActivity)

      resetTimer()

      return () => {
        window.removeEventListener('mousemove', handleUserActivity)
        window.removeEventListener('keypress', handleUserActivity)
        clearTimeout(userTimer)
      }
    }
  }, [draft])

  // ---------------------  Websocket handling  --------------------

  const draftSocket = useRef(null)
  const nominationDeadlineRef = useRef(null)
  const auctionEndingTimeRef = useRef(null)
  const auctionIsActiveRef = useRef(null)

  const openSocket = (socketFailCount = 0) => {

    if (socketFailCount >= 5 || maxRetriesReached) {
      console.log('Could not connect to Web socket after 5 attempts')
      setMaxRetriesReached(true)
      return
    }

    const socket = new WebSocket(
      `${process.env.REACT_APP_WS}/draft/${draft_id}/`
    )

    socket.addEventListener('open', () => {
      console.log('draft socket opened!')
      setMaxRetriesReached(false)
      draftSocket.current = socket

      draftSocketSend({
        action: 'open_connection',
        manager_id,
      })
    })

    socket.addEventListener('close', (event) => {
      console.log('ws closed')
      if (!event.wasClean) {
        let timeout = Math.pow(1.5, socketFailCount) * 1000 // Exponential backoff
        setTimeout(() => {
          openSocket(socketFailCount + 1) // Reconnect
        }, timeout)
      }
    })

    socket.addEventListener('error', () => {
      draftSocket.current = null
      socket.close()
    })

    // Web socket messages

    socket.addEventListener('message', function (e) {
      const data = JSON.parse(e.data)
      const action = data.action

      if (action !== 'server_time') {

        const newDraftStatusWS = data.draft_status
        const newNominationDeadline = data.nomination_deadline
        const newAuctionEndingTime = data.auction_ending_time
        const newDraftIsActive = data.draft_is_active
        const newAuctionIsActive = data.auction_is_active

        nominationDeadlineRef.current = newNominationDeadline

        auctionEndingTimeRef.current = newAuctionEndingTime

        auctionIsActiveRef.current = newAuctionIsActive

        if (newDraftStatusWS === 'in_progress') {

          newAuctionEndingTime && newAuctionIsActive
            ? setTimer(seconds_remaining(newAuctionEndingTime))
            : setTimer(seconds_remaining(newNominationDeadline))
        } else if (timer && newDraftIsActive) {
          setTimer(null)
        }

        switch (action) {
          case 'skiped_nominator':
            if (data.event_data) {
              console.log(`${timestampForLogs()}: ${data.event_data.manager} skipped due to inactivity`, data.event_data)
            }
            break
          case 'draft_started':
          case 'draft_resumed':
            draftOnlyRefetch()
            toast.success('Draft resumed!')
            break
          case 'draft_paused':
            setTimer(null)
            draftOnlyRefetch()
            toast.success('Draft paused!')
            break
          case 'draft_ended':
            setTimer(null)
            draftOnlyRefetch()
            toast.success('Draft successfully completed!')
            break
          case 'bid_made':
            if (data.event_data) {
              const event_data = data.event_data
              console.log(`${timestampForLogs()}: $${event_data.bid_amount} bid submitted by ${event_data.manager} for the ${event_data.is_offense ? 'Off' : 'Def'}-${event_data.team}`)
            }
            draftOnlyRefetch()
            break
          case 'team_nominated':
            if (data.event_data) {
              const event_data = data.event_data
              console.log(`${timestampForLogs()}: ${event_data.manager} nominated ${event_data.is_offense ? 'Off' : 'Def'}-${event_data.team}
               with ${event_data.seconds_remaining.toFixed(2)} seconds remaining in the nomination period.`)
            }
            draftOnlyRefetch()
            break
          case 'next_nominator': {
            if (data.event_data) {
              const event_data = data.event_data
              const message = event_data.expired
                ? `${event_data.manager_id == manager.id ? 'Your' : `${event_data.manager}'s`} nomination stage was skipped as time expired.`
                : `${event_data.manager_id == manager.id ? 'Your' : `${event_data.manager}'s`} nomination stage was skipped as roster is full.`
              if (event_data.expired) {
                console.log(`${timestampForLogs()}: ${event_data.manager} skipped for nominating stage as timer expired.`)
              } else {
                console.log(`${timestampForLogs()}: ${event_data.manager}'s nomination stage was skipped because their roster is full.`)
              }
              toast.info(message)
            }
            draftOnlyRefetch()
            break
          }
          case 'new_user_connected':
          case 'user_disconnected':
          case 'set_active':
          case 'set_inactive':
            draftManagersRefetch()
            // draftOnlyRefetch()
            // draftavailableTeamsRefetch()
            break
          case 'open_connection':
            break
          case 'auction_won':
            if (data.event_data) {
              const event_data = data.event_data
              const winner = event_data.winner_id == manager.id
              const message = event_data.autoclaim
                ? `${event_data.team} was awarded to ${winner ? 'You' : event_data.winner} as no other
                 managers had empty ${event_data.is_offense ? 'offensive' : 'defensive'} roster slots.`
                : `${winner ? 'You were' : `${event_data.winner} was`} awarded the ${event_data.team} with a $${event_data.bid} bid.`
              if (event_data.autoclaim) {
                console.log(`${timestampForLogs()}: ${event_data.team} was awarded to ${event_data.winner} automatically because no other managers have that roster slot open.`)
              } else {
                console.log(`${timestampForLogs()}: ${event_data.winner} was awarded ${event_data.team} at $${event_data.bid}.`)
              }
              toast.info(message)
            }
            draftManagersRefetch()
            draftOnlyRefetch()
            draftavailableTeamsRefetch()
            break
          default:
            console.warn('unknown action received')
        }
      } else {
        let serverTimestamp = new Date(data.timestamp + 'Z')  // treated as UTC
        let clientTimestamp = new Date()
        let clockSkew = clientTimestamp.getTime() - serverTimestamp.getTime() // miliseconds
        // console.log('clock skew: ', clockSkew)
        setClockSkew(clockSkew)

        const currentNominationDeadline = nominationDeadlineRef.current
        const currentAuctionEndingTime = auctionEndingTimeRef.current
        const currentAuctionIsActive = auctionIsActiveRef.current

        currentAuctionEndingTime && currentAuctionIsActive
          ? setTimer(seconds_remaining(currentAuctionEndingTime))
          : currentNominationDeadline
            ? setTimer(seconds_remaining(currentNominationDeadline))
            : setTimer(null) // so it stops

        if (!currentAuctionEndingTime && !currentAuctionIsActive && !currentNominationDeadline) {
          if (auction.ending_time && auction.is_active) {
            setTimer(seconds_remaining(auction.ending_time))
            nominationDeadlineRef.current = draft.nominationDeadline
            auctionEndingTimeRef.current = auction.ending_time
          } else if (draft.nominationDeadline) {

            setTimer(seconds_remaining(draft.nominationDeadline))
            auctionIsActiveRef.current = auction.is_active
          } else {
            setTimer(null) // so it stops
          }
        }
      }

    })
  }

  const draftSocketSend = (payload) => {
    if (!draftSocket.current) {
      return
    }

    draftSocket.current.send(JSON.stringify(payload))
  }

  useEffect(() => {
    if (!draft_id) {
      return
    }

    openSocket()

    return () => draftSocket.current?.close()
  }, [draft_id])

  // countdown calculator
  const seconds_remaining = (timeString) => {
    // console.log('time string: ', timeString)

    if (timeString) {
      let time_now = Date.now()
      let deadline_time = new Date(timeString).getTime()
      let seconds_dif = (deadline_time - time_now + clockSkew) / 1000
      //console.log('remaining seconds: ', seconds_dif)
      seconds_dif = Math.floor(seconds_dif)
      seconds_dif = Math.max(0, seconds_dif)
      return seconds_dif
    } else {
      return 'error'
    }
  }

  useEffect(() => {
    if (Math.floor(timer) < 0) {
      setTimer(null)
      dispatch(resetDraftAuction()) // needed?
    }
  }, [timer])

  return (
    <>
      <StickyBox display="flex" justifyContent="space-between">
        <Typography id="walletText" variant="h6" sx={{ color: 'black', fontSize: '16px' }}>
          Wallet: <span style={{ color: 'green' }}>${manager?.cash_remaining}</span>
        </Typography>
        <Box id="timer" display="flex" data-testid="timer-clock" alignItems={isMobile ? 'start' : 'center'}>
          <AccessTimeIcon color="primary" fontSize='small' />
          <Typography variant="h6" marginLeft="5px" sx={{ color: 'black', fontSize: '16px' }}>
            {timer}{timer ? 's' : ''}
          </Typography>
        </Box>
      </StickyBox>
    </>
  )
}
